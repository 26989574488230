<template>
  <div class="index-container">
    <xbk-room
      v-if="showXbk"
      class="liveRoom"
      :lessonId="lessonId"
      @finishClass="finishClass"
      :lessonStatus="lessonStatus"
    ></xbk-room>
    <dbk-room
      v-if="showDbk"
      class="liveRoom"
      :lessonId="lessonId"
      @finishClass="finishClass"
      :lessonStatus="lessonStatus"
    ></dbk-room>
    <o2o-room
      v-if="showO2O"
      class="liveRoom"
      :lessonId="lessonId"
      @finishClass="finishClass"
      :lessonStatus="lessonStatus"
    ></o2o-room>
    <div class="inner-row">
      <div v-show="showVideo" id="player-container" style="width:100%; height:auto;"></div>
      <div class="i-course">
        <div class="header-mid">
          <span>今日课程</span>
          <font @click="href('/manager/course/list')">更多</font>
        </div>
        <div class="data-wrap-small">
          <div class="c-list" v-if="!noTodayCourse">
            <div class="course-item" v-for="item in todayCourses" :key="item.id">
              <div class="c-cover" :style="{ backgroundImage: 'url(' + item.coverUrl + ')' }">
                <div class="c-tag">{{ courseConfig[item.courseMode] }}</div>
              </div>
              <div class="c-content">
                <div class="c-name">{{ item.courseName }}</div>
                <span>{{ item.lessonName }}</span>
                <span>授课老师：{{ item.teacherName }}</span>
                <span>上课日期：{{ item.lessonDate }}</span>
                <span>直播时长：{{ item.courseDuration }}</span>
                <div class="c-detail">
                  <span>学员数量：{{ item.studentNum }}</span>
                  <div class="c-btn1" @click="courseWareChange(item)">课件管理</div>
                  <div class="c-btn2" @click="showStudentsList(item)">学员详情</div>
                </div>
                <div
                  v-if="item.lessonStatus == '0' || item.lessonStatus == '1'"
                  class="c-start"
                  @click="toLive(item)"
                >
                  <div class="icon"></div>
                  <font>开始直播</font>
                </div>
                <div
                  v-else-if="item.lessonStatus == '2' && item.fileUrl !== null"
                  class="c-review"
                  @click="toReview(item)"
                >
                  <div class="icon"></div>
                  <font>回放</font>
                </div>
                <div v-else-if="item.lessonStatus == '2' && item.fileUrl == null" class="c-waitting">
                  <div class="icon"></div>
                  <font>生成回放中</font>
                </div>
                <div v-else class="c-finish">
                  <div class="icon"></div>
                  <font>已结束</font>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="no-data-small">
            <div class="n-image"><span>今日暂无课程</span></div>
          </div>
        </div>
      </div>
      <div class="i-news">
        <div class="header-mid">
          <span>最新消息</span>
          <font @click="href('/message')">更多</font>
        </div>
        <div class="n-list">
          <div class="n-item" v-for="item in newMsgs" :key="item.id" @click="getMsgDetail(item)">
            <div class="n-top">
              <span v-if="item.msgType == 'sys'">系统消息</span>
              <span v-else>课程消息</span>
              <font>{{ item.updateTime }}</font>
            </div>
            <p class="n-content" v-html="item.content"></p>
          </div>
        </div>
      </div>
      <div class="i-trends">
        <div class="header-full">
          <span>趋势</span>
          <font @click="href('/trends')">更多</font>
        </div>
        <div class="wrap">
          <span class="c-title">未来七天课程情况</span>
          <div ref="charts1" class="t-charts1"></div>
        </div>
        <div class="wrap">
          <span class="c-title">过去七天课程情况</span>
          <div ref="charts2" class="t-charts2"></div>
        </div>
        <div ref="charts3" class="t-charts3"></div>
      </div>
      <div class="i-comments">
        <div class="header-full">
          <span>评论</span>
          <font @click="href('/manager/course/comment')">更多</font>
        </div>
        <div class="comment-list" v-if="!noComments">
          <div class="c-item" v-for="item in courseCmts" :key="item.id">
            <div class="c-top">
              <el-rate :colors="colors" v-model="item.score" disabled></el-rate>
              <div class="c-wrap" style="display: inline-block;">
                <span style="margin-right: 10px;">{{ item.createTime }}</span>
                <span>{{ item.studentNickName }}</span>
              </div>
            </div>
            <div class="c-info">
              <div>
                授课老师:
                <span>{{ item.teacherName }}</span>
              </div>
              <div class="c-line"></div>
              <div>
                课程:
                <span>{{ item.courseName }}</span>
              </div>
            </div>
            <div class="c-content">{{ item.content }}</div>
          </div>
        </div>
        <div v-else class="no-data">
          <div class="n-image"><span>暂无数据</span></div>
        </div>
      </div>
	  <div class="i-comments">
	    <div class="header-full">
	      <span>学生对我的评论</span>
          <font @click="href('/manager/tea/comment')">更多</font>
	    </div>
	    <div class="comment-list" v-if="myCmts.length>0">
	      <div class="c-item" v-for="item in myCmts" :key="item.id">
	        <div class="c-top">
	          <el-rate :colors="colors" v-model="item.score" disabled></el-rate>
	          <div class="c-wrap" style="display: inline-block;">
	            <span style="margin-right: 10px;">{{ item.createTime }}</span>
	            <span>{{ item.studentNickName }}</span>
	          </div>
	        </div>
	        <div class="c-content">{{ item.content }}</div>
	      </div>
	    </div>
	    <div v-else class="no-data">
	      <div class="n-image"><span>暂无数据</span></div>
	    </div>
	  </div>
      <el-dialog
        top="8vh"
        class="dialog-container"
        width="80%"
        title="关联课件"
        :visible.sync="showLink"
        append-to-body
      >
        <div class="container-wrap wrap-column-center">
          <div class="link-row">
            <el-button type="success" class="link-btn" @click="linkCourseWare">批量关联</el-button>
            <el-select
              class="t-select-item"
              v-model="data.firstSubjectId"
              placeholder="请选择一级学科"
              @change="onChooseFirstSub"
            >
              <el-option
                v-for="item in firstSubject"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select
              class="t-select-item"
              v-model="data.secondSubjectId"
              @change="onChooseSecSub"
              placeholder="请选择二级学科"
            >
              <el-option
                v-for="item in secondSubject"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-input
              class="courseInput"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="courseWareName"
            ></el-input>
            <el-button type="primary" @click="searchCourseWare">搜索</el-button>
          </div>
          <el-table
            :data="teacherWareList"
            style="width: 100%"
            stripe
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="name" label="课件名称" width="250"></el-table-column>
            <el-table-column prop="fileType" label="格式" width="100"></el-table-column>
            <el-table-column prop="firstSubjectName" label="一级学科" width="120"></el-table-column>
            <el-table-column
              prop="secondSubjectName"
              label="二级学科"
              width="120"
            ></el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <p v-if="scope.row.status == '0'">转码中</p>
                <p v-if="scope.row.status == '1'">正常</p>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="上传时间" width="200"></el-table-column>
            <el-table-column label="操作" width="160">
              <template slot-scope="scope">
                <el-button size="mini" @click="linkSingle(scope.row)">关联</el-button>
                <!-- <el-button size="mini" type="danger" @click="removeCouseWare(scope.row.id, 0)">
                  删除
                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <el-dialog
        top="8vh"
        class="dialog-container"
        width="67%"
        title="上传课件"
        :visible.sync="showUpload"
        append-to-body
      >
        <div class="container-wrap wrap-column-center">
          <div class="wrap-row-center">
            <div class="c-first">
              <span class="c-text">一级学科</span>
              <el-select
                class="t-select-item"
                v-model="data.firstSubjectId"
                placeholder="请选择一级学科"
                @change="onChooseFirstSub"
              >
                <el-option
                  v-for="item in firstSubject"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div class="c-second">
              <span class="c-text">二级学科</span>
              <el-select
                class="t-select-item"
                v-model="data.secondSubjectId"
                placeholder="请选择二级学科"
                @change="onChooseSecSub"
              >
                <el-option
                  v-for="item in secondSubject"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="t-text">文件大小50M以内，格式支持 ppt/pptx 、doc/docx、pdf</div>
          <el-upload
            ref="fileUpload"
            class="c-uploader"
            :action="action"
            :headers="headers"
            :data="data"
            :multiple="false"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess"
            :on-exceed="handleExceed"
            :show-file-list="false"
          >
            <div class="c-upload">
              <div class="icon-upload"></div>
              上传课件
            </div>
            <div class="c-block" v-show="block" @click.stop="beforeUploadFiles"></div>
          </el-upload>
        </div>
      </el-dialog>
      <el-dialog
        top="8vh"
        title="课件管理"
        width="80%"
        :visible.sync="showCourseware"
        class="dialog-container"
        append-to-body
      >
        <div class="container-wrap">
          <div class="c-header">
            <div class="c-title">{{ currentCourseName }}的文档</div>
            <div class="c-btn">
              <div class="c-upload" @click="onShowUpload">
                <div class="icon-upload"></div>
                上传课件
              </div>
              <div class="c-link" @click="onShowLink">
                <div class="icon-link"></div>
                关联课件
              </div>
            </div>
          </div>
          <el-table :data="courseWareList" style="width: 100%" stripe>
            <el-table-column prop="wareName" label="课件名称" width="250"></el-table-column>
            <el-table-column prop="fileType" label="格式" width="100"></el-table-column>
            <el-table-column prop="firstSubjectName" label="一级学科" width="120"></el-table-column>
            <el-table-column
              prop="secondSubjectName"
              label="二级学科"
              width="120"
            ></el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <p v-if="scope.row.status == '0'">转码中</p>
                <p v-if="scope.row.status == '1'">正常</p>
              </template>
            </el-table-column>
            <el-table-column prop="uploadTime" label="上传时间" width="200"></el-table-column>
            <el-table-column label="操作" width="160">
              <template slot-scope="scope">
                <!--  <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
                <el-button
                  size="mini"
                  type="danger"
                  @click="removeCouseWare(scope.row.courseWareId, 1)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <el-dialog
        top="8vh"
        :title="this.currentCourseName"
        width="65%"
        :visible.sync="showStudents"
        class="dialog-container"
        append-to-body
      >
        <div class="container-wrap">
          <el-table :data="studentList" style="width: 100%" stripe>
            <el-table-column prop="name" label="姓名" width="120"></el-table-column>
            <el-table-column prop="mobileNo" label="手机号" width="150"></el-table-column>
            <el-table-column prop="nickName" label="用户名" width="150"></el-table-column>
            <el-table-column prop="email" label="邮箱" width="226"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="206"></el-table-column>
          </el-table>
          <el-pagination
            class="c-page"
            prev-text="上一页"
            next-text="下一页"
            background
            layout="prev, pager, next"
            :total="stuTotalPages"
            :page-size.sync="stuPageSize"
            @current-change="stuCurrentChange"
            :current-page.sync="stuPageIndex"
          ></el-pagination>
        </div>
      </el-dialog>
      <el-dialog
        top="8vh"
        title="消息详情"
        width="65%"
        :visible.sync="showMessage"
        class="dialog-container"
      >
        <div class="container-wrap">
          <div class="n-item">
            <div class="n-top">
              <span v-if="msgDetail.msgType == 'sys'">系统消息</span>
              <span v-if="msgDetail.msgType == 'coz'">课程消息</span>
              <font>{{ msgDetail.updateTime }}</font>
            </div>
            <p class="n-content" v-html="msgDetail.content"></p>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import xbkRoom from '@/components/live-room/xbk-room.vue';
import dbkRoom from '@/components/live-room/dbk-room.vue';
import o2oRoom from '@/components/live-room/o2o-room.vue';
export default {
  name: '',
  data() {
    return {
      showVideo: false,
      lessonStatus: '',
      block: true,
      showDbk: false,
      showO2O: false,
      showXbk: false,
      lessonId: '',
      noComments: false,
      showNoData: false,
      noTodayCourse: false,
      token: this.$.data.get('Token'),
      courseConfig: {
        gkk: '公开课',
        o2o: '一对一',
        lvb: '直播课',
        vod: '点播课',
        f2f: '面授课'
      },
      action: 'http://api.100niu.com/biz/teacher/course/uploadWare',
      courseCmts: [],
      futureCourseStat: [],
      futureCourseList: [],
      pastCourseStat: [],
      pastCourseList: [],
      newMsgs: [],
      newStudentNumStat: [],
      todayCourses: [],
      colors: ['#FE810A', '#FE810A', '#FE810A'],
      starts: 4,
      courseWareName: '',
      currentCourseID: '',
      firstSubjectIndex: 0,
      secondSubjectIndex: 0,
      firstSubjectId: '',
      secondSubjectId: '',
      firstSubject: [],
      secondSubject: [],
      courseMode: '',
      courseName: '',
      day: '',
      endDay: '',
      startDay: '',
      status: '',
      courseList: [],
      headers: {
        Authorization: 'Bearer ' + this.$.data.get('Token')
      },
      data: {
        firstSubjectId: '',
        secondSubjectId: '',
        courseId: ''
      },
      showCourseware: false,
      showStudents: false,
      showMessage: false,
      showUpload: false,
      showLink: false,
      fileList: [],
      // 总条数
      totalPages: 0,
      // 每页条数
      pageSize: 10,
      // 当前页码
      pageIndex: 1,
      msgDetail: {},
      msgID: '',
      studentList: [],
      teacherWareList: [],
      courseWareList: [],
      fileList: [],
      courseWare: [],
      futureDate: [],
      pastDate: [],
      studentStatDay: [],
      studentNumList: [],
      currentCourseID: '',
      studentList: [],
      // 总条数
      stuTotalPages: 0,
      // 每页条数
      stuPageSize: 10,
      // 当前页码
      stuPageIndex: 1,
      teacherWareIds: [],
      currentLessionID: '',
      chart1List: [],
      chart2List: [],
      currentCourseName: '',
	  myCmts:[]
    };
  },
  computed: {},
  created() {
    this.initPage();
    this.getSdkAppId();
    this.Move();
	this.getMyCmts();
  },
  mounted() {
    this.mycharts1(this.futureDate, this.chart1List);
    this.mycharts2(this.pastDate, this.chart2List);
    this.mycharts3(this.studentStatDay, this.studentNumList);
  },
  watch: {},
  methods: {
	  getMyCmts(){
		  this.post('/biz/teacher/cmt/list?pageNum=1&pageSize=1000',{}, e => {
		    if (e.code == 200) {
		      this.myCmts=e.rows
			  //console.log(this.myCmts,'this.myCmts')
		    } else {
		      //this.$.ui.toast('获取sdkAppId失败');
		    }
		  });
		  
	  },
    // 停止页面滚动
    stopMove() {
      const m = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', m, { passive: false }); // 禁止页面滑动
    },
    // 开启页面滚动
    Move() {
      const m = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = ''; // 出现滚动条
      document.removeEventListener('touchmove', m, { passive: true });
    },
    getSdkAppId() {
      this.get('/live/lvb/room/getTrtcSdkAppId', {}, e => {
        if (e.code == 200) {
          this.$.data.set('sdkAppId', e.data);
        } else {
          //this.$.ui.toast('获取sdkAppId失败');
        }
      });
    },
    beforeUploadFiles() {
      this.$.ui.toast('请选择一级学科和二级学科');
    },
    // 下课
    finishClass() {
      this.showDbk = false;
      this.showO2O = false;
      this.showXbk = false;
      this.Move();
    },
    // 初始化首页
    initPage() {
      this.showNoData = false;
      this.noComments = false;
      this.noTodayCourse = false;
      this.get('/biz/teacher/home', {}, e => {
        if (e.code == 200) {
          this.courseCmts = e.data.courseCmts.slice(0, 4);
          if (this.courseCmts.length <= 0) {
            this.noComments = true;
          }
          this.futureCourseStat = e.data.futurePastCourseStat.futureCourseStat;
          this.futureCourseStat.forEach(item => {
            this.futureDate.push(item.courseDay);
          });
          // 模拟数据
          // let courseState = [
          //   {
          //     courseDay: '06-22',
          //     courseModeList: [
          //       'lvb',
          //       'vod',
          //       'lvb',
          //       'o2o',
          //       'vod',
          //       'o2o',
          //       'gkk',
          //       'vod',
          //       'gkk',
          //       'lvb',
          //       'o2o'
          //     ]
          //   },
          //   {
          //     courseDay: '06-23',
          //     courseModeList: ['gkk', 'o2o', 'lvb', 'lvb', 'o2o', 'vod', 'o2o', 'gkk', 'vod', 'gkk']
          //   },
          //   {
          //     courseDay: '06-24',
          //     courseModeList: [
          //       'o2o',
          //       'lvb',
          //       'lvb',
          //       'o2o',
          //       'vod',
          //       'o2o',
          //       'gkk',
          //       'vod',
          //       'gkk',
          //       'lvb',
          //       'o2o',
          //       'f2f'
          //     ]
          //   },
          //   {
          //     courseDay: '06-25',
          //     courseModeList: ['vod', 'gkk', 'lvb', 'lvb', 'o2o', 'o2o', 'gkk', 'f2f', 'vod', 'gkk']
          //   },
          //   {
          //     courseDay: '06-26',
          //     courseModeList: ['lvb', 'lvb', 'o2o', 'f2f', 'vod', 'o2o', 'gkk', 'lvb', 'o2o']
          //   },
          //   {
          //     courseDay: '06-27',
          //     courseModeList: ['o2o', 'o2o', 'gkk', 'f2f', 'vod', 'gkk', 'lvb', 'o2o']
          //   },
          //   {
          //     courseDay: '06-28',
          //     courseModeList: ['lvb', 'lvb', 'o2o', 'vod', 'o2o', 'f2f', 'gkk', 'o2o']
          //   }
          // ];
          let newChart1List = [];
          let newChart2List = [];
          let conf = {
            lvb: '直播课',
            vod: '点播课',
            o2o: '一对一',
            f2f: '面授课',
            gkk: '公开课'
          };
          this.futureCourseStat.forEach((cs, cs_indecx) => {
			  let newChart1ListData={}
            cs.courseModeList.forEach(cm => {
				if(!newChart1ListData[conf[cm]])newChart1ListData[conf[cm]]=0
				newChart1ListData[conf[cm]]+=1;
              
            });
			for(let key in newChart1ListData){
			  let l = [];
			  l[cs_indecx] = newChart1ListData[key];
			  newChart1List.push({
				name: key,
				type: 'bar',
				data: l,
				stack: '0',
				barWidth: '16%'
			  });
			}
          });
          this.chart1List = newChart1List;
          this.mycharts1(this.futureDate, this.chart1List);
          console.log('init - this.futureDate', this.futureDate);
          this.pastCourseStat = e.data.futurePastCourseStat.pastCourseStat;
          this.pastCourseStat.forEach(item => {
            this.pastDate.push(item.courseDay);
          });
		  
          this.pastCourseStat.forEach((cs, cs_indecx) => {
			  let newChart2ListData={}
            cs.courseModeList.forEach(cm => {
              
			  if(!newChart2ListData[conf[cm]])newChart2ListData[conf[cm]]=0
              newChart2ListData[conf[cm]]+=1;
            });
			for(let key in newChart2ListData){
			  let l = [];
			  l[cs_indecx] = newChart2ListData[key];
			  newChart2List.push({
				name: key,
				type: 'bar',
				data: l,
				stack: '0',
				barWidth: '16%'
			  });
			}
          });
		  
		  
          this.chart2List = newChart2List;
          this.mycharts2(this.pastDate, this.chart2List);
          this.newMsgs = e.data.newMsgs.slice(0, 3);
          this.newStudentNumStat = e.data.newStudentNumStat;
          this.newStudentNumStat.forEach(item => {
            this.studentStatDay.push(item.courseDay);
            this.studentNumList.push(item.studentNum);
          });
          this.mycharts3(this.studentStatDay, this.studentNumList);
          // 今日课程
          this.todayCourses = e.data.todayCourses.slice(0, 2);
          if (this.todayCourses.length == 0) {
            this.noTodayCourse = true;
          }
        } else {
          //this.$.ui.toast(e.msg);
        }
      });
    },
    // 进入直播间
    toLive(item) {
      if (item.courseMode == 'o2o') {
        this.showO2O = true;
      } else if (item.courseMode == 'gkk') {
        this.showDbk = true;
      } else if (item.courseMode == 'lvb') {
        this.showXbk = true;
      }
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
      this.stopMove();
      this.lessonStatus = item.lessonStatus;
      this.lessonId = item.lessonId;
    },
    // 当前页变化时执行
    stuCurrentChange(e) {
      this.stuPageIndex = e;
      this.getCourseStudent();
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length +
          fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 获取消息详情
    getMsgDetail(item) {
      if (item.msgType == 'sys') {
        this.getSysDetail(item.id);
      } else if (item.msgType == 'coz') {
        this.getCozDetail(item.id);
      }
    },
    // 获取课程消息详情
    getCozDetail(id) {
      this.msgID = id;
      this.get(`/biz/teacher/msg/cozMsg/detail?msgId=${this.msgID}`, {}, e => {
        if (e.code == 200) {
          this.msgDetail = e.data;
          this.showMessage = true;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 获取系统消息详情
    getSysDetail(id) {
      this.msgID = id;
      this.get(`/biz/teacher/msg/sysMsg/detail/${this.msgID}`, {}, e => {
        if (e.code == 200) {
          this.msgDetail = e.data;
          this.showMessage = true;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 上传成功
    uploadSuccess(response) {
      // 上传课件后，更新课件状态
      console.log(response);
      if (response.code == 200) {
        this.post('/biz/teacher/course/temp/update/course/ware/status', {}, e => {
          if (e.code == 200) {
            this.$.ui.toast('上传成功');
            setTimeout(() => {
              this.showUpload = false;
              this.onShowLink();
            }, 1000);
          } else {
            this.$.ui.toast(e.msg);
          }
        });
      } else {
        this.$.ui.toast(response.msg);
      }
    },
    // 展示课程学生详情
    showStudentsList(item) {
      this.showStudents = true;
      this.currentCourseID = item.courseId;
      this.currentLessionID = item.lessonId;
      this.getCourseStudent();
    },
    // 获取课程已关联的课件列表
    getCourseWares() {
      this.get(`/biz/teacher/course/wares/${this.currentCourseID}`, {}, e => {
        if (e.code == 200) {
          this.courseWareList = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 展示上传列表
    courseWareChange(item) {
      this.currentCourseName = item.courseName;
      this.currentCourseID = item.courseId;
      this.data.courseId = item.courseId;
      this.showCourseware = true;
      this.getCourseWares();
    },
    // 获取课程已关联的课件列表
    getCourseWares() {
      this.get(`/biz/teacher/course/wares/${this.currentCourseID}`, {}, e => {
        if (e.code == 200) {
          this.courseWareList = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    //课程列表的学员详情
    getCourseStudent() {
      this.post(
        `/biz/teacher/course/studentsInfo?pageNum=${this.stuPageIndex}&pageSize=${
          this.stuPageSize
        }`,
        { courseId: this.currentCourseID, lessonId: this.currentLessionID },
        e => {
          if (e.code == 200) {
            this.studentList = e.rows;
            this.stuTotalPages = e.total;
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 展示课程学生详情
    showStudentsList(item) {
      this.showStudents = true;
      this.currentCourseID = item.courseId;
      this.currentLessionID = item.lessonId;
      this.getCourseStudent();
    },
    mycharts1(futureDate, list) {
      let myChart = this.$echarts.init(this.$refs.charts1, 'macarons');
      let option = {
        title: {},
        color: [
          'rgb(0, 122, 255)',
          'rgb(255, 204, 0)',
          'rgb(236,138,93)',
          'rgb(20, 204, 204)',
          'rgb(158,202,127)'
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          },
          position: function(pt) {
            //提示框的位置
            return [pt[0], 30];
          },
          formatter: function(params) {
            let newList = [];
            console.log(params);
            for (let i = 0; i < params.length; i++) {
              if (params[i].value) {
                newList.push(
                  '<p>' + params[i].marker + params[i].seriesName + ':' + params[i].value + '</p >'
                );
              }
            }
            return newList.join('');
          }
        },
        grid: {
          //图表和父盒子之间的距离
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '17%',
          containLabel: true
        },
        legend: {
          //图例
          data: [
            {
              name: '一对一',
              textStyle: {
                color: '#575759'
              }
            },
            {
              name: '直播课',
              textStyle: {
                color: '#575759'
              }
            },
            {
              name: '点播课',
              textStyle: {
                color: '#575759'
              }
            },
            {
              name: '公开课',
              textStyle: {
                color: '#575759'
              }
            },
            {
              name: '面授课',
              textStyle: {
                color: '#575759'
              }
            }
          ]
        },
        xAxis: {
          //x轴
          type: 'category',
          // boundaryGap: false,
          data: futureDate,
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#89898C',
              fontSize: 10
            },
            margin: 8
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(2,121,253)'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            //x轴的坐标文字
            show: true,
            textStyle: {
              color: '#89898C' //文字的颜色
            }
          },
          max: 10,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(2,121,253)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            //坐标在grid区域的分割线
            lineStyle: {
              //设置分割线的样式(图表横线颜色)
              color: ['#153a8a']
            }
          }
        },
        series: []
      };
      option.series = list;
      myChart.setOption(option);
      //让图表自适应
      window.addEventListener('resize', function() {
        myChart.resize(); // myChart 是实例对象
      });
    },
    mycharts2(pastDate, list) {
		
		console.log('_list-------',list)
		/*
		let listData={};
		_list.forEach((item)=>{
			if(!listData[item.name])listData[item]=0;
			listData[item]+=1;
		})
		let list=[];
		for(let key  in listData){
			list.push({name:key,vlaue:listData[key]})
		}
		*/
      let myChart = this.$echarts.init(this.$refs.charts2, 'macarons');
      let option = {
        title: {},
        color: [
          'rgb(0, 122, 255)',
          'rgb(255, 204, 0)',
          'rgb(236,138,93)',
          'rgb(20, 204, 204)',
          'rgb(158,202,127)'
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          },
          position: function(pt) {
            //提示框的位置
            return [pt[0], 30];
          },
          formatter: function(params) {
            let newList = [];
            console.log(params);
            for (let i = 0; i < params.length; i++) {
              if (params[i].value) {
                newList.push(
                  '<p>' + params[i].marker + params[i].seriesName + ':' + params[i].value + '</p >'
                );
              }
            }
            return newList.join('');
          }
        },
        grid: {
          //图表和父盒子之间的距离
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '17%',
          containLabel: true
        },
        legend: {
          //图例
          data: [
            {
              name: '一对一',
              textStyle: {
                color: '#575759'
              }
            },
            {
              name: '直播课',
              textStyle: {
                color: '#575759'
              }
            },
            {
              name: '点播课',
              textStyle: {
                color: '#575759'
              }
            },
            {
              name: '公开课',
              textStyle: {
                color: '#575759'
              }
            },
            {
              name: '面授课',
              textStyle: {
                color: '#575759'
              }
            }
          ]
        },
        xAxis: {
          //x轴
          type: 'category',
          // boundaryGap: false,
          data: pastDate,
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#89898C',
              fontSize: 10
            },
            margin: 8
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(2,121,253)'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            //x轴的坐标文字
            show: true,
            textStyle: {
              color: '#89898C' //文字的颜色
            }
          },
          max: 10,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(2,121,253)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            //坐标在grid区域的分割线
            lineStyle: {
              //设置分割线的样式(图表横线颜色)
              color: ['#153a8a']
            }
          }
        },
        series: []
      };
      option.series = list;
      myChart.setOption(option);
      //让图表自适应
      window.addEventListener('resize', function() {
        myChart.resize(); // myChart 是实例对象
      });
    },
    mycharts3(x, y) {
      let myChart = this.$echarts.init(this.$refs.charts3, 'macarons');
      myChart.setOption({
        title: {
          text: '近一个月学员趋势图',
          textStyle: {
            width: 150
          }
        },
        color: ['rgb(0, 122, 255)', 'rgb(255, 204, 0)', 'rgb(88, 86, 214)', 'rgb(20, 204, 204)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999'
            }
          },
          position: function(pt) {
            //提示框的位置
            return [pt[0], 30];
          }
        },
        grid: {
          //图表和父盒子之间的距离
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '17%',
          containLabel: true
        },
        legend: {},
        xAxis: {
          //x轴
          type: 'category',
          // boundaryGap: false,
          data: x,
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#89898C',
              fontSize: 10
            },
            margin: 8
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(2,121,253)'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            //x轴的坐标文字
            show: true,
            textStyle: {
              color: '#89898C' //文字的颜色
            }
          },
          max: 100,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgb(2,121,253)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            //坐标在grid区域的分割线
            lineStyle: {
              //设置分割线的样式(图表横线颜色)
              color: ['#153a8a']
            }
          }
        },
        series: [
          {
            name: '学员增长数量',
            type: 'line', //柱状图
            data: y,
            barWidth: '8%' //柱状体的宽度
          }
        ]
      });
      //让图表自适应
      window.addEventListener('resize', function() {
        myChart.resize(); // myChart 是实例对象
      });
    },
    // 获取一级学科列表
    getFirstSubject() {
      this.get('/biz/subject/list', { pid: '' }, e => {
        if (e.code === 200) {
          // 将一级学科索引归零
          this.firstSubjectIndex = 0;
          this.firstSubject = e.data;
          this.getSecondSubject(this.firstSubject[0].id);
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 获取二级学科列表
    getSecondSubject(pid) {
      this.get('/biz/subject/list', { pid: pid }, e => {
        if (e.code === 200) {
          // 将二级学科索引归零
          this.secondSubjectIndex = 0;
          this.secondSubject = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 更改一级学科下拉框时
    onChooseFirstSub(e) {
      console.log(e);
      console.log('this.data.firstSubjectId', this.data.firstSubjectId);
      console.log('this.data.secondSubjectId', this.data.secondSubjectId);
      if (this.data.firstSubjectId !== '' && this.data.secondSubjectId !== '') {
        this.block = false;
      } else {
        this.block = true;
      }
      this.getSecondSubject(e);
    },
    onChooseSecSub(e) {
      console.log('this.data.firstSubjectId', this.data.firstSubjectId);
      console.log('this.data.secondSubjectId', this.data.secondSubjectId);
      if (this.data.firstSubjectId !== '' && this.data.secondSubjectId !== '') {
        this.block = false;
      } else {
        this.block = true;
      }
    },
    // 展示关联课件
    onShowLink() {
      this.getFirstSubject();
      this.getTeacherWares();
      this.showLink = true;
    },
    // 展示上传组件
    onShowUpload() {
      this.showUpload = true;
      this.getFirstSubject();
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const file1 = testmsg === 'ppt';
      const file2 = testmsg === 'pptx';
      const file3 = testmsg === 'doc';
      const file4 = testmsg === 'docx';
      const file5 = testmsg === 'pdf';
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!file1 && !file2 && !file3 && !file4 && !file5) {
        this.$message.error('上传文件格式支持 ppt/pptx 、doc/docx、pdf ！');
      }
      if (!isLt50M) {
        this.$message.error('上传文件大小不能超过 50MB!');
      }
      let isFile = file1 || file2 || file3 || file4 || file5;
      return isFile && isLt50M;
    },
    // 取得教师的课件列表
    getTeacherWares() {
      this.post(
        `/biz/teacher/course/teacherWares`,
        {
          courseId: this.currentCourseID,
          firstSubjectId: this.firstSubjectId,
          name: this.courseWareName,
          secondSubjectId: this.secondSubjectId
        },
        e => {
          if (e.code == 200) {
            this.teacherWareList = e.rows;
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 搜索课件
    searchCourseWare() {
      this.getTeacherWares();
    },
    // 关联课件
    linkCourseWare() {
      if (this.teacherWareIds.length > 0) {
        this.post(
          '/biz/teacher/course/joinWares',
          { courseId: this.currentCourseID, teacherWareIds: this.teacherWareIds },
          e => {
            if (e.code == 200) {
              this.$.ui.toast('课件关联成功');
              setTimeout(() => {
                this.showLink = false;
                this.getCourseWares();
                // 成功后清空数组
                this.teacherWareIds = [];
              }, 1000);
            } else {
              this.$.ui.toast(e.msg);
            }
          }
        );
      } else {
        this.$.ui.toast('请选择关联课件');
      }
    },
    // 关联单个课件
    linkSingle(item) {
      let index = this.teacherWareIds.indexOf(item.id);
      if (index == -1) {
        this.teacherWareIds.push(item.id);
      }
      // 执行关联
      this.linkCourseWare();
    },
    // 取消关联课件
    removeCouseWare(id, type) {
      this.post(`/biz/teacher/course/remove/${id}`, {}, e => {
        if (e.code == 200) {
          this.$.ui.toast('课件取消关联成功');
          setTimeout(() => {
            if (type == 0) {
              this.getTeacherWares();
            } else if (type == 1) {
              this.getCourseWares();
            }
          }, 1000);
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    handleSelectionChange(val) {
      this.teacherWareIds = val;
      this.teacherWareIds = this.teacherWareIds.map(item => {
        return item.id;
      });
      console.log('val', val);
      console.log('this.teacherWareIds', this.teacherWareIds);
    },
    // 点击回看按钮
    toReview(item) {
      this.showVideo = true;
      setTimeout(() => {
        document.getElementById('player-container').innerHTML = '';
        this.player = new TcPlayer('player-container', {
          mp4: item.fileUrl,
          // RTMP:`http://1305813075.vod2.myqcloud.com/live/${item.matUrl}`,
          autoplay: true, // iOS下safari浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
          poster: item.coverUrl,
          width: '100%', // 视频的显示宽度，请尽量使用视频分辨率宽度
          height: '100%' // 视频的显示高度，请尽量使用视频分辨率高度
        });
      }, 100);
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    }
  },
  components: {
    xbkRoom: xbkRoom,
    dbkRoom: dbkRoom,
    o2oRoom: o2oRoom
  }
};
</script>

<style lang="less">
.liveRoom {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
}
.t-charts1 {
  width: 100%;
  height: 100%;
}
.el-dialog {
  border-radius: 10px;
}
.dialog-container {
  /deep/ .el-dialog {
    border-radius: 10px !important;
  }
  .el-dialog__header {
    line-height: 30px;
    height: 30px;
    text-align: center;
    padding: 5px;
    background: #f5f5f7;
    border-radius: 10px 10px 0 0;
    .el-dialog__title {
      height: 20px;
      font-size: 14px;
      font-family: SF Pro Text;
      font-weight: 500;
      line-height: 20px;
      color: #45494d;
    }
  }
  .el-dialog__body {
    padding: unset;
    background: #ffffff;
    border-radius: 10px;
  }
  .el-dialog__body {
    border-radius: 10px;
  }
  .el-dialog__headerbtn {
    top: 15px;
  }
}
</style>
